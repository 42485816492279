@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

/*
  elm-hot creates an additional div wrapper around the app to make HMR possible.
  This could break styling in development mode if you are using Elm UI.

  More context in the issue:
    https://github.com/halfzebra/create-elm-app/issues/320
*/
[data-elm-hot="true"] {
  height: inherit;
}


body {
  @apply bg-neutral-700 dark:bg-neutral-950;
}

p {
  @apply pb-2;
}

